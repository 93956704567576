.tippy-box {
  background-color: #E4EDF5;
  font-weight: 400;
  margin-right: 4px;
  color: #282828;
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
  border-top-color: #E4EDF5;
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
  border-bottom-color: #E4EDF5;
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
  border-left-color: #E4EDF5;
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
  border-right-color: #E4EDF5;
}

.tippy-box>.tippy-backdrop {
  background-color: #E4EDF5;
}

.tippy-box>.tippy-svg-arrow {
  fill: #E4EDF5;
}