@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #F1F6F9;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #CADDEB;
  /* border: 1px solid #005da3; */
  border-radius: 4px;
}
