.Wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: var(--spacing);
  margin-bottom: -1px;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;

    .TreeItem {
      --vertical-padding: 5px;

      border-radius: 4px;
      box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0;
        height: 48px;
        border-color: #E4EDF5;
        background-color: #E4EDF5;

        &:before {
          position: absolute;
          left: -8px;
          top: -4px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        >* {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem>* {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.TreeItem {
  --vertical-padding: 0;

  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: var(--justifyContent);
  padding: var(--vertical-padding) 0px;
  background-color: var(--bgColor);
  border: 1px solid #d3d3d3;
  border-radius: 4px;

  border-bottom-color: var(--borderBottomColor);
  border-bottom-left-radius: var(--border-b-l-radius);
  border-bottom-right-radius: var(--border-b-r-radius);

  color: #282828;
  box-sizing: border-box;
  font-size: 14px;
  gap: 24px;
}

.Text {
  flex-grow: 1;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #005DA3;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {

  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.Collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}